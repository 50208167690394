import Registro from '@/components/Paciente/Registro.vue';
<template>
    <div>
        <b-navbar class="bg_red py-2" type="dark" toggleable="lg">
            <b-navbar-brand href="#">
                <b-img class="svg" src="../assets/Healtech_heart.svg" alt=""/>
                <router-link to="/main" tag="span" custom v-slot="{navigate}">
                    <span class="pl-2" @click="navigate" @keypress.enter="navigate"><span class="txt_white  title">HEALTECH</span></span>
                </router-link>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-navbar-nav>
                        <b-nav-item href="#">
                            <router-link to="/main" tag="span" custom v-slot="{navigate}">
                                <span class="option mr-3" @click="navigate" @keypress.enter="navigate"><span class="txt_white">INICIO</span></span>
                            </router-link>
                            <router-link to="/registro" tag="span" custom v-slot="{navigate}">
                                <span class="option mr-3" @click="navigate" @keypress.enter="navigate"><span class="txt_white">REGISTRO</span></span>
                            </router-link>
                        </b-nav-item>
                    </b-navbar-nav>
                    <b-nav-form>
                        <div class="d-flex content">
                            <input class="form-control bg-transparent border-right-0 py-1 mr-2 input_navbar" placeholder="Buscar paciente">
                            <a class="float-right py-2 mr-3 " href="">
                                <i class="fa fa-search txt_white"></i>
                            </a>
                        </div>
                    </b-nav-form>

                    <b-nav-item-dropdown right>
                        <template #button-content>
                            <i class="fa fa-user fa-lg txt_white"></i>
                        </template>
                        <b-dropdown-item class="dropdown-color">
                            <span class="fa fa-user"></span>
                            <span class="pl-2">Perfil</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="logout()">
                            <span class="fa fa-arrow-left"></span>
                            <span class="pl-2">Salir</span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <Logout :abrir="openLogout"/>
    </div>
</template>

<script>
import Logout from '@/components/Modals/Logout.vue'
import { EventBus } from '../EventBus';

export default {
    name: 'Navbar',
    components: { Logout },

    data:() => ({
        openLogout: false
    }),

    methods: {
        logout() {
            this.openLogout = true;
        }
    },

    mounted() {
        EventBus.$on('cerrarModalLogout',()=>{
            this.openLogout = false;
        })
    }
}
</script>

<style scoped>
    .svg {
        height: 40px;
        width: 40px;
        transform: scale(1.4);
    }

    .content {
        border: solid 1px white;
        border-radius: 1rem;
        margin-right: 10px;
    }
    
    .input_navbar {
        border: none;
        color:white;
        width: 400px;
        outline: none !important;
        box-shadow: none !important;
    }

    .input_navbar::placeholder {
        color:white;
    }

    @media only screen and (max-width: 500px) {
        .input_navbar {
            width: auto;
        }
    }

    .form-rounded {
        border: none;
        padding-left: 0;
        margin-left: 0;
    }

    a {
        text-decoration: none;
        color: white;
    }

    .title {
        font-weight: 500;   
    }

    .color  {
        color: white !important;
    }

    .option {
        font-weight: 400;
    }

    .option:hover {
        border-bottom: 2px solid #fff;
    }

</style>